<template>
    <v-overlay :value="parameters.loadingGlobal.showLoadingGlobal" :opacity="0.7" z-index="999">
        <v-progress-circular
            :indeterminate="parameters.loadingGlobal.indeterminate"
            :size="parameters.loadingGlobal.size"
            :color="parameters.secondaryColor"
            :rotate="parameters.loadingGlobal.rotate"
            :value="parameters.loadingGlobal.value"
            width="15"
        > {{ parameters.loadingGlobal.value ? `${parseFloat(parameters.loadingGlobal.value).toFixed(2)}%` : "" }}</v-progress-circular>
        <p class="pt-4" v-html="parameters.loadingGlobal.text"></p>
    </v-overlay>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
};
</script>

<style>
</style>